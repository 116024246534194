.App {
  height: 100vh;
  padding: 64px;
}
.App h1 {
  padding-bottom: 2rem;
  font-size: 3.75rem;
  width: fit-content;
  margin: 0
}
form button.MuiButton-root{
  font-size: 1.1rem;
  padding: 10px 20px;
  font-family: Barlow;
  text-transform: unset;
}
button{
  margin-top: 0!important;
}